import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { IoMdOpen } from "react-icons/io";
import {
  IoPeopleOutline,
  IoTrendingUpOutline,
  IoGlobeOutline,
} from "react-icons/io5";

import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Image from "../components/image";
import Seo from "../components/seo";

import CornerGraphic from "../images/graphics/Flexi_Corner_Light.svg";
import FlexiTimeTeam from "../images/staff/FlexiTime_ABV2021.jpg";

import DroppahLogo from "../images/logos/Droppah/Droppah_Single.svg";
import PayHeroLogo from "../images/logos/PayHero/PayHero_Single.svg";
import KarmlyLogo from "../images/logos/Karmly/Karmly_Single.svg";
import InvoxyLogo from "../images/logos/Invoxy/Invoxy_Single.svg";

const BenefitRow = styled(Row)`
  &.item-0 {
    justify-content: flex-start;
  }

  &.item-1 {
    justify-content: center;
  }

  &.item-2 {
    justify-content: flex-end;
  }

  > div {
    width: calc((100% / 3) + 100px);

    svg {
      flex: 0 0 auto;
      font-size: 2.5rem;
      color: ${(props) => props.theme.colours.Primary} !important;
    }

    h3 {
      flex: 1 1 auto;
      font-weight: 400;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
    }
  }
`;

const CornerGraphicContainer = styled.img`
  position: absolute;
  width: 45%;
  user-select: none;
  pointer-events: none;

  &.-left {
    bottom: 0;
    left: 0;
  }

  &.-right {
    top: 0;
    right: 0;
    transform: rotate(180deg);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 85%;
  }
`;

const SolutionBox = styled(Box)`
  padding: 0 0 0 80px;

  .product-badge {
    aspect-ratio: 1 / 1;
    width: 100%;
    max-width: 80px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

    img {
      height: 40px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
  }
`;

const IndexPage = (props) => {
  const { shift, employee, contractor } = useStaticQuery(
    graphql`
      query {
        shift: file(relativePath: { eq: "home/BRs/Shift_BR.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        employee: file(relativePath: { eq: "home/BRs/Employee_BR.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contractor: file(relativePath: { eq: "home/BRs/Contractor_BR.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const benefits = [
    {
      icon: <IoPeopleOutline />,
      description:
        "We empower the modern workforce by building innovative tools and solutions that promote flexible work arrangements.",
    },
    {
      icon: <IoTrendingUpOutline />,
      description:
        "We believe in the power of technology to break down traditional barriers and create new opportunities for work and growth.",
    },
    {
      icon: <IoGlobeOutline />,
      description:
        "We are dedicated to creating a future where individuals and businesses can thrive in a dynamic, connected world.",
    },
  ];

  const solutions = [
    {
      background: shift.childImageSharp.fluid,
      solution: "Shift Workers",
      image: "ShiftWorkers_Image.png",
      bg: "deeppurple",
      link: "https://www.droppah.com",
      products: [
        {
          name: "Droppah",
          description: "Rostering / Timeclock",
          logo: DroppahLogo,
        },
      ],
    },
    {
      background: employee.childImageSharp.fluid,
      solution: "Employees",
      image: "Employees_Image.png",
      bg: "PayHeroPrimary",
      link: "https://www.payhero.co.nz",
      products: [
        {
          name: "PayHero",
          description: "Leave & Time / Payroll / Tax",
          logo: PayHeroLogo,
        },
      ],
    },
    {
      background: contractor.childImageSharp.fluid,
      solution: "Contractors",
      image: "Contractors_Image.png",
      bg: "KarmlyPrimary",
      link: "https://www.karmly.com/recruitment",
      products: [
        {
          name: "Karmly",
          description: "Contractor Management / Recruitment",
          logo: InvoxyLogo,
        },
      ],
    },
  ];

  return (
    <Layout lightNav>
      <Seo
        title="Workforce Suite | Shift Workers, Employees & Contractors | FlexiTime"
        description="FlexiTime helps ambitious businesses manage and pay their flexible workforce."
        pathname={props.location.pathname}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "${process.env.GATSBY_SITE_URL}",
              "name": "FlexiTime",
              "legalName": "FlexiTime Limited",
              "description": "Onboard, roster, manage and pay your staff with FlexiTime’s people platform. Get started for free.",
              "logo": "${process.env.GATSBY_SITE_URL +
            "/metaImages/FlexiTimeSchemaLogo.png"}",
              "foundingDate": "2009",
              "founders": [
                {
                "@type": "Person",
                "name": "Robert Owen"
                } 
              ],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "11 Chews Lane",
                "addressLocality": "Willis Street",
                "addressRegion": "Wellington",
                "postalCode": "6143",
                "addressCountry": "New Zealand"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "Customer Support",
                "email": "support@flexitime.works"
              },
              "sameAs": [ 
                "https://www.facebook.com/FlexiTimeTeam",
                "https://twitter.com/FlexiTimeTeam",
                "https://www.linkedin.com/company/flexitime",
                "https://www.youtube.com/channel/UCYP8fYDx74HaMc2RzuLojhg"
              ]
            }
          `}
        </script>
      </Helmet>
      <Container bg="Tertiary" className="-whiteOut">
        <CornerGraphicContainer className="-left" src={CornerGraphic} alt="" />
        <CornerGraphicContainer className="-right" src={CornerGraphic} alt="" />
        <Wrapper stackGap={150}>
          <Box stackGap={35}>
            <h1 className="-fontLarge">
              <span className="-fontPrimary">Welcome to FlexiTime.</span>
            </h1>
          </Box>
          <Box stackGap={60}>
            {benefits.map((item, i) => {
              return (
                <BenefitRow
                  data-sal="slide-right"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                  className={`item-` + i}
                >
                  <Row justify="flex-start" stackGap={20}>
                    {item.icon}
                    <h3>{item.description}</h3>
                  </Row>
                </BenefitRow>
              );
            })}
          </Box>
          <Box stackGap={30}>
            <h2 className="h1 -fontPrimary">The world of work has changed.</h2>
            <p className="h3 -fontNormal">
              Businesses are increasingly adopting hybrid workforce strategies
              and people are choosing flexibility over the security of permanent
              work.
            </p>
            <p className="h3 -fontNormal">
              Work hours are variable and dynamic, workplaces are increasingly
              distributed or remote, and contracting and freelancing are on the
              rise.
            </p>
            <p className="h3 -fontNormal">
              FlexiTime helps ambitious businesses manage and pay their flexible
              workforce. Our modern workforce suite includes solutions for
              payroll, time and attendance, leave management, contractor
              management, rostering and recruitment.
            </p>
            {/* <p className="h3 -fontNormal">
              We’ve built a suite of tools that will help you grow your business
              and build a better work life for you and your team.
            </p> */}
          </Box>
        </Wrapper>
      </Container>
      <Container id="brands" className="-whiteOut">
        {solutions.map((item, i) => {
          return (
            <BackgroundImage
              Tag="section"
              fluid={item.background}
              key={i}
              backgroundColor={`#fff`}
              style={{
                backgroundPosition:
                  item.solution === "Contractors" ? "top right" : "center",
              }}
            >
              <Wrapper stackGap={200}>
                <Row alignCenter>
                  <SolutionBox stackGap={30} size={60}>
                    <h2>{item.solution}</h2>
                    {item.products.map((item, i) => {
                      return (
                        <Row
                          justify="flex-start"
                          key={i}
                          alignCenter
                          stackGap={20}
                          noBreak
                        >
                          <div className="product-badge">
                            <img src={item.logo} />
                          </div>
                          <Box stackGap={3}>
                            <h3 css={{ marginBottom: "0" }}>{item.name}</h3>
                            <h4>{item.description}</h4>
                          </Box>
                        </Row>
                      );
                    })}
                    <a
                      className="link-floating -fontNormal"
                      target="_blank"
                      rel="noreferrer"
                      href={item.link}
                    >
                      Learn More{" "}
                      <IoMdOpen css={{ top: "2px", marginLeft: "5px" }} />
                    </a>
                  </SolutionBox>
                </Row>
              </Wrapper>
            </BackgroundImage>
          );
        })}
      </Container>
      {/* <Container bg="Tertiary" className="-whiteOut">
        <Wrapper>
          <Box stackGap={20}>
            <p className="h3 -fontNormal">
              We help business owners, employees and independent contractors
              achieve success in their work and life. We help freelancers and
              employers worldwide make contracting fair and payroll easy.
            </p>
            <p className="h3 -fontNormal">
              We’re committed to helping people work better. Across a range of
              products and markets, we help thousands of companies streamline
              their people and payment processes.
            </p>
            <p className="h3 -fontNormal">
              <Link to="/about-us">Learn More</Link>
            </p>
          </Box>
        </Wrapper>
      </Container> */}
      <Container bg="Tertiary" className="-whiteOut -light" graphicalBR>
        <Wrapper>
          <Box stackGap={30}>
            <h2 className="h1 -fontPrimary">Our Story</h2>
            <p className="h3 -fontNormal">
              Like many tech companies, FlexiTime began because our founders had
              a need and spotted an opportunity. We’ve forged our own way,
              building a world class SaaS business from the ground up.
            </p>
            <p className="h3 -fontNormal">
              We’ve come a long way since launching our first product in 2008, a
              payroll system for small NZ businesses. Now there are tens of
              thousands of people around the world using our products to do
              better business each and every day.
            </p>
            <p className="h3 -fontNormal">
              One thing that hasn’t changed is our obsessive focus on
              innovation, design and usability. We know that smart technology
              used in the right way can transform lives for the better.
            </p>
          </Box>
          {/* <img
            src={FlexiTimeTeam}
            alt="FlexiTime Team 2021"
            css={{ opacity: "0.7" }}
          /> */}
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default IndexPage;
